
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/notice/notification/BasicUsage.vue";
import EUIWithTypes from "@/views/resources/documentation/element-ui/notice/notification/WithTypes.vue";
import EUICustomPosition from "@/views/resources/documentation/element-ui/notice/notification/CustomPosition.vue";
import EUIWithOffset from "@/views/resources/documentation/element-ui/notice/notification/WithOffset.vue";
import EUIUseHTMLString from "@/views/resources/documentation/element-ui/notice/notification/UseHTMLString.vue";
import EUIHideCloseButton from "@/views/resources/documentation/element-ui/notice/notification/HideCloseButton.vue";

export default defineComponent({
  name: "notification",
  components: {
    EUIBasicUsage,
    EUIWithTypes,
    EUICustomPosition,
    EUIWithOffset,
    EUIUseHTMLString,
    EUIHideCloseButton
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Notification");
    });
  }
});
